<template>
  <div>
    <v-container lg="12" xl="8">
      <v-card v-bind:key="image" v-for="image in imageList" class="d-flex" width="300" height="300">
        <v-card-title>{{ image | onlyName }}</v-card-title>
<!--        <v-img :src="require('../assets/images/' + image)" ></v-img>-->
<!--        <v-img :src="'https://bing.com/th?id=OHR.' + image" />-->
        <v-img :src="'/upload/pics/' + image" />
        <v-card-actions>
          <v-btn color="orange" text @click="openImage(image)" >View Full Size</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
    <v-btn @click="randomImageList">I am lucky</v-btn>
  </div>
</template>

<script>
import { images } from "@/api/images"

const ITEM_SHOW_PER_AGE = 12

export default {
  name: "ImageGallery",
  data(){
    return {
      imageList: null
    }
  },
  filters:{
    onlyName(image){
      return image.substr(0, image.search('_'))
    }
  },
  mounted() {
    this.randomImageList()
  },
  methods:{
    randomImageList(){
      this.imageList  = images.sort(() => .5 - Math.random()).slice(0, ITEM_SHOW_PER_AGE)
    },
    loadImageSrc(image){
      // let imageObj = require('@/assets/images/' + image)
      // console.log(imageObj)
      return "../assets/images/" + image
    },
    openImage(src){
      // window.location.href = 'https://bing.com/th?id=OHR.' + src
      // window.location.href=require('../assets/images/' + src)
      window.location.href = '/upload/pics/' + src
    }
  }
}
</script>

<style lang="scss" scoped>

.container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .v-card{
    margin: 30px;
  }
}

</style>
