<template>
  <div>
    <v-container lg="12" xl="8">
        <div class="logo">
          <svg-icon type="mdi" :path="logoIcon" class="svg-icon"></svg-icon>
          <div>Bing Bing Share Media</div>
        </div>
    </v-container>
  </div>
</template>

<script>

import SvgIcon from '@jamescoyle/vue-icon';
import { mdiBeeFlower } from '@mdi/js';

export default {
  name: "TopNav",
  components: {
    SvgIcon
  },
  data(){
    return {
      logoIcon: mdiBeeFlower,
    }
  }
}
</script>

<style lang="scss" scoped>

.container{
  display: flex;
  flex-direction: row;
  .logo{
    .svg-icon{
      width: 60px;
      height: 60px;
      color: lightseagreen;
    }
    display: flex;
    align-items: end;
    font-size: 30px;
    flex-direction: row;
    margin-left: 60px;
  }
}

</style>
