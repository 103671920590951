import { render, staticRenderFns } from "./ImageGallery.vue?vue&type=template&id=b71bc9f4&scoped=true&"
import script from "./ImageGallery.vue?vue&type=script&lang=js&"
export * from "./ImageGallery.vue?vue&type=script&lang=js&"
import style0 from "./ImageGallery.vue?vue&type=style&index=0&id=b71bc9f4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b71bc9f4",
  null
  
)

export default component.exports