<template>
  <div class="home">
    <top-nav />
    <v-container lg="12" xl="8">
      <v-card flat>
        <v-card-title>Do you like the bing wall page everyday?</v-card-title>
        <v-card-text>
          When you get a fresh new start in the morning, Bing will always get your a new open gate with surprising images.
          Browse beautiful Bing images beyond your imagination.We have collected all kinds of beautiful Bing photos every day for your convenience.
          Every picture is really beautiful
        </v-card-text>
      </v-card>
    </v-container>
    <ImageGallery />
    <v-container class="bottom-banner">Copyright <svg-icon :size=18 type="mdi" :path="mdiCopyright"></svg-icon> {{ new Date().getFullYear() }} Bing Share Media</v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import ImageGallery from '@/components/ImageGallery'
import TopNav from "@/components/TopNav";

import SvgIcon from '@jamescoyle/vue-icon';
import { mdiCopyright } from '@mdi/js';



export default {
  name: 'HomeView',
  components: {
    TopNav,
    SvgIcon,
    ImageGallery
  },
  data(){
    return {
      mdiCopyright: mdiCopyright
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  padding: 60px;
}

.bottom-banner{
  display: flex; justify-content: center; align-items: center; border-top: gray 1px solid; margin-top: 25px;
  color: cornflowerblue;
  .svg{
    margin: 10px;
  }
}
</style>
